import React from 'react';
import './../css/RowTabla.css';
import MiBoton from './../components/MiBoton.jsx';

Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~ ~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

class RowTabla extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            cambios: 0,
            rows: this.props.data.datos,
            titulo: null,
            txtAnchos: "",
            titulos: null,
            detalle: null,
            footer: null
        }

        this.evtChange = this.evtChange.bind(this);
        this.evtClick = this.evtClick.bind(this);
        this.evtChangeNums = this.evtChangeNums.bind(this);
        this.evtClickFijo = this.evtClickFijo.bind(this);
        this.calculos = this.calculos.bind(this);
        this.actDatos = this.actDatos.bind(this)
    }
    componentDidMount(){
        this.actDatos();
    }

    componentDidUpdate(prevProps, prevState){
        /* if (prevProps.total_valores!= this.props.total_valores){
            let ahora = this.state.cambios + 1;
            this.setState({
                cambios: ahora
            })
        } */
        if (prevProps.data != this.props.data){
            this.setState({
                rows: this.props.data.datos
            })
        }
        if (prevState.rows!=this.state.rows){
            this.actDatos();
        }
    }

    evtChangeNums(event){
        const target = event.target;
        const nombre = target.name;
        let valor = target.value;


        let indice = parseInt(target.getAttribute("data-id"))

        let larow = this.state.rows[indice];
        larow.valor = valor;
        this.setState({
            rows: this.state.rows.map((row, i)=>{
                if (i==indice){
                    return larow;
                }
                return row;
            })
        })

        try {
            this.props.evtChangeCampos(nombre, valor);
        } catch(error){
            console.log(error)
        }
       
        this.calculos();

    }

    calculos(){
        let total = 0.00;
        for (var i=0; i<this.state.rows.length;i++){
            let este = parseFloat(this.state.rows[i].valor) || 0.00;
            total = total + este;
        }
        
        this.props.total_valores(total.format(2,3,',', '.'));
    }

    evtClickFijo(nombre, valor, event){
        let ck = document.getElementById(nombre);
        let marca = ck.checked;
        
        let indice = parseInt(ck.getAttribute("data-id"))
        if (!ck.checked){
            valor = 0.00;
        }
        let larow = this.state.rows[indice];
        larow.valor = valor;
        larow.marca = marca;
        larow.presenta = parseFloat(valor).format(2,3,',', '.')
        this.setState({
            rows: this.state.rows.map((row, i)=>{
                if (i==indice){
                    return larow;
                }
                return row;
            })
        })
        try {
            this.props.evtChangeCampos(nombre, valor);
        } catch(error){
            console.log(error)
        }
        
        this.calculos();
    }

    evtChange(event){
        const target = event.target;
        const nombre = target.name;
        this.props.evtChangeCampos(nombre, target.value);
    }

    quitaLetras(texto){
        let resultado = "";
        for(var i = 0; i< texto.length; i++){
            let letra = texto.charAt(i);
            let code = texto.charCodeAt(i);
            if ((code >=48 && code <= 57) || code==46){
                resultado = resultado + letra;
            }

        }
        return resultado;
    }

    evtClick(nombre){
        this.props.evtClick(nombre);
    }

    actDatos(){
        var txtAnchos = "";
        var obj_footer = null;
        var a_totales = [];
        const datos = this.props.data;
        datos.titulos.map((item)=>{
            txtAnchos += item.ancho + " ";
        });
        if(this.props.footer){
            var a;
            for(a=0;a<this.props.footer.length;a++){
                a_totales.push(0)
            }
        }
        var num_col = 0;
        const titulos = datos.titulos.map((item)=>
            {
                let justifica = datos.justifica[num_col];
                let claseJustifica = "";
                if (justifica=='C') {claseJustifica = "cen"}
                if (justifica=='I') {claseJustifica = "izq"}
                if (justifica=='D') {claseJustifica = "der"}
                num_col++;
                return(<div className={"misCols " + claseJustifica}>{item.texto}</div>)
            });
        let nrow = 0;
        const detalle = datos.datos.map((item)=>{
            num_col = 0;
            const columnas = item.columnas.map((col)=> {
                let justifica = datos.justifica[num_col];
                let claseJustifica = "";
                if (justifica=='C') {claseJustifica = "cen"}
                if (justifica=='I') {claseJustifica = "izq"}
                if (justifica=='D') {claseJustifica = "der"}
                if (this.props.footer){
                    let valor = col;
                    if (valor!=null){
                        valor = valor.toString().replace(",", "").replace(",", "").replace(",", "");
                        if (!isNaN(valor)){
                            a_totales[num_col] += parseFloat(valor);
                        }
                    }
                    
                }
                num_col++;
                let nombre_campo = "";
                let tipo_campo = 0;
                let obj = null;
                if (col.length>0){
                    if (col.substr(0,7)=="[campo]"){
                        tipo_campo = 1;
                        nombre_campo = col.substr(7, col.length-7) + item.id;
                        obj = <input className="form-control" name={nombre_campo} autoComplete="off"  onChange={this.evtChange} />;
                    }
                    if (col.substr(0, 7)=="[boton]"){
                        tipo_campo = 2;
                        let resto = col.substr(8, col.length-8);
                        let partes = resto.split(",");
                        nombre_campo = partes[0] + item.id;
                        obj = <MiBoton texto={partes[1]} miNombre={nombre_campo} evtClick={this.evtClick}  />;
                    }
                    if (col.substr(0,7)=="[links]"){
                        tipo_campo = 3;
                        let resto = col.substr(7, col.length-7);
                        let partes = resto.split(",");
                        obj = <a className="links" href={partes[0]}>{partes[1]}</a>;
                    }
                    if (col.substr(0,7)=="[valor]"){
                        tipo_campo = 4;
                        nombre_campo = col.substr(7, col.length-7) + item.id;
                        obj = <input className="form-control tabla_valores" data-id={nrow} name={nombre_campo} autoComplete="off" onChange={this.evtChangeNums}  type="number" value={this.state.rows[nrow].valor} />;
                    }
                    if (col.substr(0,7)=="[valo2]"){
                        tipo_campo = 5;
                        nombre_campo = col.substr(7, col.length-7) + item.id;
                        obj = <div class="form-check">
                                <input class="form-check-input" data-id={nrow} type="checkbox" checked={this.state.rows[nrow].marca} id={nombre_campo} onChange={(event)=>{this.evtClickFijo(nombre_campo, item.montofijo, event);}}/>
                                <label class={"form-check-label "} id={"lb_" + nombre_campo} for={nombre_campo}>{this.state.rows[nrow].presenta}</label>
                                <input type='hidden' className={'tabla_valores '} id={"vl_" + nombre_campo}  value={this.state.rows[nrow].valor} />
                             </div>;
                    }
                }
                
                if (tipo_campo==0){
                    obj = <span>{col}</span>
                }
                return (<div className={"misCols " + claseJustifica}>{obj}</div>)
            });
            if(this.props.footer){
                num_col = 0;
                const obj_footer_cols = this.props.footer.map((elem)=>{
                    let justifica = datos.justifica[num_col];
                    let claseJustifica = "";
                    if (justifica=='C') {claseJustifica = "cen"}
                    if (justifica=='I') {claseJustifica = "izq"}
                    if (justifica=='D') {claseJustifica = "der"}
    
                    var valor = (elem=="sum"?parseFloat(a_totales[num_col]).format(2,3,',','.'):elem)
                    num_col++;
                    return(<div className={"misCols b " + claseJustifica}>{valor}</div>);
                });
                obj_footer = <div className="misTablaRows" style={{gridTemplateColumns: txtAnchos}}>{obj_footer_cols}</div>
            }
            nrow++;
            return (<div className="misTablaRows misTablasReg" key={item.id}  style={{gridTemplateColumns: txtAnchos}}>
                {columnas}
            </div>)
        }
        );
        this.setState({
            titulo: datos.titulo,
            txtAnchos: txtAnchos, 
            titulos: titulos,
            detalle: detalle,
            footer: obj_footer 
        })
    }

    render() {
        
        
        return(
            <div className='misTablas' >
                <input type="hidden" value={this.state.cambios} />
                <div className="misTablasTitulo">{this.state.titulo}</div>
                <div className="misRows misTablasHeader" style={{gridTemplateColumns: this.state.txtAnchos}}>{this.state.titulos}</div>
                <div className="misTablasBody">
                    {this.state.detalle}
                </div>
                {this.state.footer}
            </div>
        )
    }
}

export default RowTabla;