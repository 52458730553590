import React from "react";
import './../css/Recargas.css';
import MiSelect from './MiSelect.jsx';
import MiEdit from './MiEdit.jsx';
import MiBoton from "./MiBoton";
import Wait from './Wait.jsx';
import Mensaje from './Mensaje.jsx';
import Confirmacion from './Confirmacion.jsx';
import Pregunta from './Pregunta.jsx';

Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~ ~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

class Recargas extends React.Component {
    constructor(props){
        super(props);
        this.props.titulo("Recargas");

        this.state = {
            limite: 0.00,
            usado: 0.00,
            disponible: 0.00,
            ultimo: '',
            compania: 0,
            celular: '',
            monto: '0.00',
            buscandoDatos: true,
            mensaje: '',
            clase_mensaje: 'Info',
            cierre_mensaje: null,
            mostrar_confirmacion: 'N',
            pregunta_seguridad: true
        }
        this.evtChange = this.evtChange.bind(this);
        this.puedeRecarga = this.puedeRecarga.bind(this);
        this.evtClickSolicitar = this.evtClickSolicitar.bind(this);
        this.evtCerrarMensaje = this.evtCerrarMensaje.bind(this);
        this.evtCerrarConf = this.evtCerrarConf.bind(this);
        this.evtClickConfirmacion = this.evtClickConfirmacion.bind(this);
        this.procesoCierre = this.procesoCierre.bind(this);
        this.grabaRecarga = this.grabaRecarga.bind(this);
        this.respuesta_correcta = this.respuesta_correcta.bind(this);
        
        this.inicioRecargas();
    }

    evtChange(event){
        const target = event.target;
        const nombre = target.name;
        this.setState({
            [nombre]: target.value
        })
    }

    async inicioRecargas(){
        var resultado = false;
        this.setState({
            buscandoDatos: true
        })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var miData = new URLSearchParams();
        miData.append("cliente", localStorage.getItem("cliente"));
        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
          try {
            const response = await fetch("https://api.cooeprouasd.net/api/recargas/inicio", requestOptions);
            if (response.ok){
                const data = await response.json();
                if (data.respuesta=="S"){
                    resultado = true;
                    this.setState({
                        limite: parseFloat(data.limite),
                        usado: parseFloat(data.usado),
                        disponible: parseFloat(data.limite)-parseFloat(data.usado),
                        ultimo: data.ultimocelular
                    })
                } else {
                    this.setState({
                        mensaje: data.mensaje,
                        clase_mensaje: "Error"
                    })
                    
                }
            } else {
                this.setState({
                    mensaje: "Ha ocurrido un error de conexión. Por favor intente más tarde",
                    clase_mensaje: "Error"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Error: " + error,
                clase_mensaje: "Error"
            })
          }
          this.setState({
              buscandoDatos: false
          })
        return resultado;
    }

    procesoCierre(){
        this.props.procesoCierre();
    }
    async grabaRecarga(){
        var resultado = false;
        this.setState({
            buscandoDatos: true
        })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var miData = new URLSearchParams();
        miData.append("cliente", localStorage.getItem("cliente"));
        miData.append("compania", this.state.compania);
        miData.append("telefono", this.state.celular);
        miData.append("monto", this.state.monto);
        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
          try {
            const response = await fetch("https://api.cooeprouasd.net/api/recargas/grabar", requestOptions);
            if (response.ok){
                const data = await response.json();
                if (data.respuesta=="S"){
                    resultado = true;
                    this.setState({
                        mensaje: "Su recarga ha sido solicitada exitosamente.",
                        clase_mensaje: "Info",
                        cierre_mensaje: this.procesoCierre
                    })
                } else {
                    this.setState({
                        mensaje: data.mensaje,
                        clase_mensaje: "Error"
                    })
                    
                }
            } else {
                this.setState({
                    mensaje: "Ha ocurrido un error de conexión. Por favor intente más tarde",
                    clase_mensaje: "Error"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Error: " + error,
                clase_mensaje: "Error"
            })
          }
          this.setState({
              buscandoDatos: false
          })
        return resultado;
    }

    evtClickSolicitar(event){
        if (this.puedeRecarga()){
            this.setState({
                mostrar_confirmacion: 'S'
            })
        }
    }

    evtCerrarMensaje(event){
        this.setState({
            mensaje: ""
        })
        if (this.state.cierre_mensaje !=null){
            this.state.cierre_mensaje();
        }
    }

    evtCerrarConf(event){
        this.setState({
            mostrar_confirmacion: 'N'
        })
    }

    evtClickConfirmacion(event){
        if (this.puedeRecarga()){
            this.grabaRecarga();
        }
    }

    puedeRecarga(){
        var resultado = true;
        var mensaje = "";
        if (parseInt(this.state.compania)==0){
            resultado = false;
            mensaje = "Debe seleccionar la compañía"
        }
        if (resultado && this.state.celular.length!=10){
            resultado = false;
            mensaje = "Debe digitar el celular"
        }
        if (resultado && parseFloat(this.state.monto)<10){
            resultado = false;
            mensaje = "Debe digitar el monto de la recarga"
        }
        if (resultado && parseFloat(this.state.monto)>this.state.disponible){
            resultado = false;
            mensaje = "Monto solicitado mayor a disponible"
        }
        if (!resultado){
            this.setState({
                mensaje: mensaje,
                clase_mensaje: "Error"
            })
        }
        return resultado;
    }

    respuesta_correcta(){
        this.setState({
            pregunta_seguridad: false
        })
    }

    render(){
        const datos_empresas = [
            {codigo:"01", nombre: "CLARO", padre:0},
            {codigo:"30", nombre: "ALTICE", padre:0},
            {codigo:"50", nombre: "VIVA", padre:0},  
            {codigo:"55", nombre: "DIGICEL", padre:0},
            {codigo:"56", nombre: "MOUN", padre:0}
    ]
        const obj_wait = (this.state.buscandoDatos?<Wait />: null);
        const obj_mensaje = (this.state.mensaje.length>0 ? <Mensaje clase={this.state.clase_mensaje} texto={this.state.mensaje} evtCerrar={this.evtCerrarMensaje} />:null);
        const obj_confirmacion = (this.state.mostrar_confirmacion=="S"? <Confirmacion enEspera={this.state.buscandoDatos} texto="¿Desea solicitar esta recarga?"  evtCerrar={this.evtCerrarConf} evtClick={this.evtClickConfirmacion} />:null);
        const obj_seguridad = (this.state.pregunta_seguridad? <Pregunta respuesta_correcta={this.respuesta_correcta} procesoCierre={this.procesoCierre} />: null);
        return(
            <div className="Div-Recargas">
                {obj_wait}
                {obj_seguridad}
                {obj_mensaje}
                {obj_confirmacion}
                <div className="RecRows">
                    <div><label className='EtiqRec'>Límite mensual recargas:</label></div>
                    <div><label className='EtiqHist'>{(this.state.limite).format(2,3,',','.')}</label></div>
                </div>
                <div className="RecRows">
                    <div><label className='EtiqRec'>Este mes usted ha recargado:</label></div>
                    <div><label className='EtiqHist'>{(this.state.usado).format(2,3,',','.')}</label></div>
                </div>
                <div className="RecRows">
                    <div><label className='EtiqRec b'>Disponible:</label></div>
                    <div><label className='EtiqHist'>{(this.state.disponible).format(2,3,',','.')}</label></div>
                </div>
                <div className="RecRaya"></div>
                <div className="RecRows" >
                    <div style={{paddingTop: '4px'}}><label className=' EtiqRec b'>Compañía:</label></div>
                    <MiSelect filas={datos_empresas} padre={0} miNombre="compania" evtCambios={this.evtChange} valor={this.state.compania} />
                </div>
                <div className="RecRows">
                    <div><label className='EtiqRec b'>Celular:</label></div>
                    <div><input type="number" className="form-control" name="celular" value={this.state.celular} onChange={this.evtChange} style={{maxWidth: '200px'}} /></div>
                </div>
                <div className="RecRows">
                    <div><label className='EtiqRec b'>Monto:</label></div>
                    <MiEdit miNombre="monto" valor={this.state.monto} evtChange={this.evtChange} />
                </div>
                <div style={{display: 'grid', gridTemplateColumns: '1fr 200px 1fr'}}>
                    <div></div>
                    <MiBoton texto="Solicitar" evtClick={this.evtClickSolicitar} />
                    <div></div>
                </div>
            </div>
        )
    }
}

export default Recargas;