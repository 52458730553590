import React from "react";
import RowTabla from './RowTabla';

import "./../css/PanelDetalle.css";

Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~ ~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

class PanelDetalle extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            datos: [{id:null, columnas: []}]
        }
        this.actDatos = this.actDatos.bind(this);
    }
   

    async actDatos(){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var miData = new URLSearchParams();
        miData.append("cliente", this.props.cliente);

        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/Resumen/panel_prestamos";
        if (this.props.tipo == 2){url = "https://api.cooeprouasd.net/api/Resumen/panel_ahorros"}
          try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();

                if (data[0].respuesta=="S"){
                    var a;
                    var resp_datos = []
                    for(a=0;a<data.length; a++){
                        let item = {};
                        item.id = data[a].id;
                        let este_tipo = data[a].tipo;
                        let este_balance = data[a].balance
                        if (this.props.tipo==1){
                            let este_fecha = data[a].fecha;
                            let este_monto = data[a].monto;
                            item.columnas = [este_tipo, este_fecha, parseFloat(este_balance).format(2,3,',','.')]
                        } else {
                            item.columnas = [este_tipo, parseFloat(este_balance).format(2,3,',','.')]
                        }
                        resp_datos.push(item);
                    }
                    
                    this.setState({
                        datos: resp_datos
                    });
                } else {
                    //alert("mensaje: " + data.mensaje)
                    
                }
            } else {
                //alert("Error de conexión...");
            }
          } catch(error){
            //alert("Error: " + error);
          }
    }

    componentDidMount(){
        this.actDatos()
    }

    render() {
        var a_titulos = [
            {texto: 'Tipo', ancho: '2fr'},
            {texto: 'Fecha', ancho: '1fr'},
            {texto: 'Balance', ancho: '1fr'}
            ];
        var a_justifica = ['L', 'C', 'D'];
        if (this.props.tipo==2){
            a_titulos = [
                {texto: 'Tipo', ancho: '2fr'},
                {texto: 'Balance', ancho: '1fr'}
                ];
                a_justifica = ['L', 'D'];
        }
        
        
         var datos = {
            titulo: (this.props.tipo==1?'Mis Compromisos':'Mis Ahorros'),
            titulos: a_titulos,
            justifica: a_justifica,
            datos: this.state.datos
        }; 
        return(
            <div id="cuerpo-detalle">
                <RowTabla data={datos} />
            </div>
        )
    }
}

export default PanelDetalle;