import React from 'react';
import './../css/Mensaje.css';
import MiBoton from './MiBoton';

class Mensaje extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            visible: "fade-in-mens"
        }
        this.cerrarDialogo = this.cerrarDialogo.bind(this);
        this.procesosCierre = this.procesosCierre.bind(this);
    }

    cerrarDialogo(event){
        this.setState({
            visible: "fade-out-mens",
            visible_contenido: 0
        })
        setTimeout(()=>this.procesosCierre(), 100);
    }

    procesosCierre(){
        this.props.evtCerrar();
        this.setState({
            visible: "fade-in-mens",

        })
    }


    render(){

        return(
            <div className="Fondo-Mensajex" >
                <div className={"Div-Mensaje fade-in-mens " + this.props.clase}  >
                    <div className="Div-Titulo">
                        <div className="Div-Titulo-Icon"></div>
                        <div>{this.props.clase}</div>
                    </div>
                    <div className="Div-Mensaje-Texto">{this.props.texto}</div>
                    <div className="Div-Mensaje-Boton">
                        <div></div>
                        <MiBoton  texto="Ok" miNombre="btConfirmarOk" evtClick={this.cerrarDialogo} />
                        <div></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Mensaje;