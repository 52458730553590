import React from "react";
import './../css/MiEdit.css';

Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~ ~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

class MiEdit extends React.Component {
    constructor(props){
        super(props);

        this.evtCambios = this.evtCambios.bind(this);
        this.evtFocus = this.evtFocus.bind(this);
        this.evtKey = this.evtKey.bind(this);
        this.evtBlur = this.evtBlur.bind(this);

    }

    
    evtCambios(event){
        this.props.evtChange(event);
    }

    evtFocus(event){
        let obj = event.currentTarget;
        obj.select();
    }

    evtKey(key){
        if (key.charCode < 48 || key.charCode > 57) {
            if (!(key.charCode == 190 || key.charCode == 46 || key.charCode == 13)) {
                key.preventDefault()
            }
        }
    }

    evtBlur(event){
        /* let texto = "0.00"
        if (this.state.valor.length!=0){
            texto = parseFloat(this.state.valor).format(2,3,',','.');
        } 
        this.setState({
            valor: texto
        }) */
    }

    componentDidUpdate(){
        
    }


    render(){
        let texto = "0.00"
        let actual = this.props.valor;
        if (this.props.length!=0){
            actual = actual.replace(",", "").replace(",", "").replace(",", "").replace(",", "")
            texto = parseFloat(actual).format(2,3,',','.');
        } 
        return(
            <div className="Div-MiEdit">
                <div className="MiEdit-Formato">{texto}</div>
                <div><input type="number" name={this.props.miNombre} onBlur={this.evtBlur}  autoComplete="off" onKeyPress={this.evtKey} onChange={this.evtCambios} onFocus={this.evtFocus} className="form-control MiEdit" value = {this.props.valor}/></div>
            </div>
        )
    }

}

export default MiEdit;
