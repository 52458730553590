import React from 'react';
import MiBoton from './MiBoton';
import MiEdit from './MiEdit';
import MiInfoValor from './MiInfoValor';
import MiSelect from './MiSelect';
import Confirmacion from './Confirmacion';
import Mensaje from './Mensaje';
import './../css/Ordenes.css';
import Wait from './Wait';
import Pregunta from './Pregunta.jsx';

Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~ ~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

class Ordenes extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            tipo_casa: 0,
            casa_comercial: 0,
            mostrar_confirmacion: 'N',
            cuotas: 0,
            datos_tipos : [],
            datos_casas: [],
            datos_cuotas: [],
            derecho: '0.00',
            derecho_normal: '0.00',
            derecho_farmacia: '0.00',
            cuotas_disabled: 'disabled',
            monto : '0.00',
            ini: 0,
            cuota_capital: 0.00,
            cuota_interes: 0.00,
            cuota_total: 0.00,
            grabando_orden: false,
            texto_mensaje: "",
            clase_mensaje: "Error",
            cierre_mensaje: null,
            buscandoDatos: true,
            pregunta_seguridad: true
        }
        this.evtChange = this.evtChange.bind(this);
        this.evtClickSolicitar = this.evtClickSolicitar.bind(this);
        this.evtClickConfirmacion = this.evtClickConfirmacion.bind(this);
        this.evtCerrarConf = this.evtCerrarConf.bind(this);
        this.inicio = this.inicio.bind(this);
        this.puedeGrabar = this.puedeGrabar.bind(this);
        this.grabaOrden = this.grabaOrden.bind(this);
        this.procesoCierre = this.procesoCierre.bind(this);
        this.evtCerrarMensaje = this.evtCerrarMensaje.bind(this);
        this.respuesta_correcta = this.respuesta_correcta.bind(this);

        this.inicio();
    }

    actMonto(monto, cuotas, tipo){
        let cuota_capital = 0.00;
        let cuota_interes = 0.00;
        let cuota_total = 0.00;

        if (tipo=="13"){
            if (monto <= 2000) {
                cuotas = 1
            } else {
                if (monto > 2000 && monto <= 4000) {
                    cuotas = 2
                } else {
                    if (monto > 4000 && monto <= 6000) {
                        cuotas = 3
                    } else {
                        if (monto > 6000 && monto <= 8000) {
                            cuotas = 4
                        } else {
                            if (monto > 8000 && monto <= 10000) {
                                cuotas = 5
                            } else {
                                if (monto > 10000 && monto <= 15000) {
                                    cuotas = 6
                                } else {
                                    cuotas = 7
                                }
                            }

                        }

                    }

                }

            }
            cuota_capital = monto / cuotas;
            cuota_interes = 0.00;
            cuota_total = cuota_capital;
            this.setState({
                cuotas: cuotas,
                cuota_interes: cuota_interes,
                cuota_capital: cuota_capital,
                cuota_total: cuota_total
            })
        } else {
            var a;
            var constante = 0.00;
            var tasa = 0.00;
            for(a=0;a<this.state.datos_tipos.length;a++){
                if (this.state.datos_tipos[a].codigo==tipo){
                    constante = parseFloat(this.state.datos_tipos[a].constante);
                    tasa = parseFloat(this.state.datos_tipos[a].tasa);
                    break;
                }
            }
            console.log("monto: " + monto + ", cuotas: " + cuotas + ", constante: " + constante + ", tasa: " + tasa)
            if (monto > 0 && cuotas > 0 && constante > 0 && tasa > 0) {
                cuota_interes = ((monto * (cuotas + 1)) / constante) / cuotas;
                console.log("cuota interes: " + cuota_interes)
            }
            if (monto > 0 && cuotas > 0) {
                cuota_capital = monto / cuotas;
            }
            cuota_total = cuota_capital+cuota_interes;
            this.setState({
                cuota_interes: cuota_interes,
                cuota_capital: cuota_capital,
                cuota_total: cuota_total
            })
        }
        
        
    }

    evtChange(event){
        const target = event.target;
        const nombre = target.name;

        if (nombre=="tipo_casa"){
            let derecho = this.state.derecho_normal;
            let disabled = '';
            if (target.value == '13'){
                derecho = this.state.derecho_farmacia;
                disabled = 'disabled';
            }
            this.setState({
                tipo_casa: target.value,
                casa_comercial: 0,
                derecho: derecho,
                cuotas_disabled: disabled
            })
            let mnt = parseFloat(this.state.monto);
            let cuotas = this.state.cuotas;
            this.actMonto(mnt, cuotas, target.value);
        }
        if (nombre=="casa_comercial"){
            this.setState({
                casa_comercial: target.value
            })
        }
        if (nombre=="orden_cuotas"){
            this.setState({
                cuotas: target.value
            })
            let mnt = parseFloat(this.state.monto);
            let cuotas = parseInt(target.value)
            let tipo = this.state.tipo_casa;
            this.actMonto(mnt, cuotas, tipo);
        }
        if (nombre=="orden_monto"){
            let monto = target.value;
            this.setState({
                monto: target.value
            })
            let mnt = parseFloat(monto);
            let cuotas = parseInt(this.state.cuotas);
            let tipo = this.state.tipo_casa;
            this.actMonto(mnt, cuotas, tipo);
        }
    }

    procesoCierre(){
        this.props.procesoCierre();
    }

    componentDidMount(){
        
    }
    
    componentDidUpdate(){
        
    }

    evtClickSolicitar(event){
        if (this.puedeGrabar()){
            this.setState({
                mostrar_confirmacion: 'S'
            })
        }
        
    }

    evtClickConfirmacion(event){
        if (this.puedeGrabar()){
            this.grabaOrden();
        }
    }

    evtCerrarConf(){
        this.setState({
            mostrar_confirmacion: 'N'
        })
    }

    evtCerrarMensaje(){
        this.setState({
            texto_mensaje: ""
        })
        if (this.state.cierre_mensaje !=null){
            this.state.cierre_mensaje();
        }
        
    }

    async loadTipos(){
        var derecho_normal = '0.00';
        var derecho_farmacia = '0.00';
        var a_tipos = [];
        var a_cuotas = [];
        //let itema = {codigo: '0', nombre: '(Seleccione)', padre: '0'}
        //a_tipos.push(itema);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var miData = new URLSearchParams();
        miData.append("cliente", localStorage.getItem("cliente"));

        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/ordenes/inicio";
          try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();

                if (data[0].respuesta=="S"){
                    var a;
                    for(a=0;a<data.length; a++){
                        derecho_normal = data[a].derecho;
                        derecho_farmacia = data[a].farmacia;
                        let item = {};
                        item.codigo = data[a].codigo;
                        item.nombre = data[a].nombre;
                        item.constante = data[a].constante;
                        item.tasa = data[a].tasa;
                        item.padre = '0';
                        a_tipos.push(item);

                        var b;
                        for(b=1;b<=parseInt(data[a].cuotas);b++){
                            let itemb = {};
                            itemb.codigo = b;
                            itemb.nombre = b.toString();
                            itemb.padre = data[a].codigo;
                            a_cuotas.push(itemb);
                        }
                    }
                    
                    this.setState({
                        datos_tipos: a_tipos,
                        datos_cuotas: a_cuotas,
                        derecho_normal: derecho_normal,
                        derecho_farmacia: derecho_farmacia
                    });
                } else {
                    alert("mensaje: " + data.mensaje)
                    
                }
            } else {
                //alert("Error de conexión...");
            }
          } catch(error){
            //alert("Error: " + error);
          }
    }

    async loadCasas(){
        var a_casas = [];
        let item = {codigo: '0', nombre: '(Seleccione)', padre: '0'}
        a_casas.push(item);

        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/ordenes/casas";
          try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();

                if (data[0].respuesta=="S"){
                    var a;
                    for(a=0;a<data.length; a++){
                        let item = {};
                        item.codigo = data[a].codigo;
                        item.nombre = data[a].nombre;
                        item.padre = data[a].tipo;
                        a_casas.push(item);
                    }
                    
                    this.setState({
                        datos_casas: a_casas
                    });
                } else {
                    //alert("mensaje: " + data.mensaje)
                    
                }
            } else {
                //alert("Error de conexión...");
            }
          } catch(error){
            //alert("Error: " + error);
          }
    }

    async inicio(){
        this.props.titulo("Solicitud de Ordenes");
        await this.loadTipos();
        await this.loadCasas();
        this.setState({
            tipo_casa: '0',
            casa_comercial: 0,
            monto: '0.00',
            derecho: '0.00',
            cuotas: 0,
            buscandoDatos: false
        })
    }

    valorNumerico(monto){
        var resultado = 0.00;
        if (monto){
            if (monto != null){
                monto = monto.replace(",", "").replace(" ", "").replace(" ", "").replace(",", "");
                resultado = parseFloat(monto);
                if (isNaN(resultado)){
                    resultado = 0.00;
                }
            }
        }
        return resultado;
    }

    puedeGrabar(){
        var puede = true;
        var mensaje = "";
        var monto = this.valorNumerico(this.state.monto);
        var derecho = this.valorNumerico(this.state.derecho);
        if (this.state.tipo_casa == 0) {
            puede = false;
            mensaje = "Debe seleccionar el tipo de casa comercial.";
        }
        if (this.state.casa_comercial == 0 && puede) {
            puede = false;
            mensaje = "Debe seleccionar la casa comercial.";
        }
        if (monto< 1 && puede) {
            puede = false;
            mensaje = "Debe digitar el monto de la orden.";
        }
        if (this.state.cuotas == 0 && puede) {
            puede = false;
            mensaje = "Debe digitar la cantidad de cuotas.";
        }
        if (monto>derecho && puede) {
            puede = false;
            mensaje = "Monto mayor al disponible. ";
        }
        if (!puede) {
            this.setState({
                texto_mensaje: mensaje,
                clase_mensaje: "Error"
            })
        }
        return puede;
    }

    async grabaOrden(){
        this.setState({
            grabando_orden: true
        })
        let tk = "";
        try{
            tk = localStorage.getItem("tk");
        } catch(error){

        }
        var myHeaders = new Headers();
        myHeaders.append("tk", tk);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        let monto = this.valorNumerico(this.state.monto);
        var miData = new URLSearchParams();
        miData.append("cliente", localStorage.getItem("cliente"));
        miData.append("casacomercial", this.state.casa_comercial);
        miData.append("monto", monto);
        miData.append("cuotas", this.state.cuotas);

        var requestOptions = {
            method: 'POST',
            body: miData,
            headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/ordenes/grabar";
          try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();

                if (data.respuesta=="E"){
                    this.props.sesion_expirada();
                    return;
                } 

                if (data.respuesta=="S"){
                    this.setState({
                        grabando_orden: false,
                        texto_mensaje: "Su orden ha sido solicitada exitosamente!",
                        clase_mensaje: "Info",
                        cierre_mensaje: this.procesoCierre
                    })

                } else {
                    this.setState({
                        texto_mensaje: data.mensaje,
                        grabando_orden: false
                    })
                }
            } else {
                //alert("Error de conexión...");
                this.setState({
                    texto_mensaje: "Ha ocurrido un error de conexión. Por favor intente más tarde",
                    grabando_orden: false
                })
            }
          } catch(error){
            //alert("Error: " + error);
            this.setState({
                texto_mensaje: "Ha ocurrido un error en la conexión. Por favor intente más tarde",
                grabando_orden: false
            })
          }
    }
    
    respuesta_correcta(){
        this.setState({
            pregunta_seguridad: false
        })
    }

    render(){
        const datos_tipos = this.state.datos_tipos;
        const datos_casas = this.state.datos_casas;
        const datos_cuotas = this.state.datos_cuotas;
        const obj_wait = (this.state.buscandoDatos?<Wait />: null);
        const obj_confirmacion = (this.state.mostrar_confirmacion=="S"? <Confirmacion enEspera={this.state.grabando_orden} texto="¿Desea solicitar esta orden?" visible={this.state.mostrar_confirmacion} evtCerrar={this.evtCerrarConf} evtClick={this.evtClickConfirmacion} />:null);
        const obj_mensaje = (this.state.texto_mensaje.length>0 ? <Mensaje clase={this.state.clase_mensaje} texto={this.state.texto_mensaje} evtCerrar={this.evtCerrarMensaje} />:null);
        const obj_seguridad = (this.state.pregunta_seguridad? <Pregunta respuesta_correcta={this.respuesta_correcta} procesoCierre={this.procesoCierre} />: null);
        return(
            <div className="Div-Ordenes">
                {obj_mensaje}
                {obj_confirmacion}
                {obj_wait}
                {obj_seguridad}
                <div className="rowOrd" >
                    <div><label className=' EtiqOrd'>Tipo de Casa Comercial:</label></div>
                    <MiSelect filas={datos_tipos} padre={0} miNombre="tipo_casa" evtCambios={this.evtChange} valor={this.state.tipo_casa} />
                </div>
                <div className="rowOrd">
                    <div><label className=' EtiqOrd'>Casa Comercial:</label></div>
                    <MiSelect filas={datos_casas} padre={this.state.tipo_casa} miNombre="casa_comercial" evtCambios={this.evtChange} valor={this.state.casa_comercial}/>
                </div>
                <div className="misRows" style={{gridTemplateColumns: '1fr 200px 1fr'}}>
                    <div></div>
                    <MiInfoValor valor={parseFloat(this.state.derecho).format(2,3,',','.')} label="Usted tiene derecho a..." />
                    <div></div>
                </div>
                <div className="rowOrd">
                    <div><label className='EtiqOrd grande'>Monto de la Orden:</label></div>
                    <MiEdit maximo={this.state.derecho} miNombre="orden_monto" valor={this.state.monto} evtChange={this.evtChange} />
                </div>
                <div className="rowOrd">
                    <div><label className='EtiqOrd '>Cuotas:</label></div>
                    <MiSelect ancho="200px" filas={datos_cuotas} disabled={this.state.cuotas_disabled} evtCambios={this.evtChange} padre={this.state.tipo_casa} miNombre="orden_cuotas" valor={this.state.cuotas}/>
                </div>
                <div className="rowOrd">
                    <div></div>
                    <div className="rowOrd2">
                        <div><label className='EtiqOrd b'>Cuota Interés:</label></div>
                        <div><label className='datosCuotas '>{parseFloat(this.state.cuota_interes).format(2,3,',','.')}</label></div>
                    </div>
                </div>
                <div className="rowOrd">
                    <div></div>
                    <div className="rowOrd2">
                        <div><label className='EtiqOrd b'>Cuota Capital:</label></div>
                        <div><label className='datosCuotas '>{parseFloat(this.state.cuota_capital).format(2,3,',','.')}</label></div>
                    </div>
                </div>
                <div className="rowOrd">
                    <div></div>
                    <div className="rowOrd2">
                        <div><label className='EtiqOrd b'>Total Cuota:</label></div>
                        <div><label className='datosCuotas '>{parseFloat(this.state.cuota_total).format(2,3,',','.')}</label></div>
                    </div>
                </div>
                <div className="misRows" style={{gridTemplateColumns: '1fr 200px 1fr', marginTop: '10px'}}>
                    <div></div>
                    <MiBoton texto="Solicitar" miNombre="btSolicitar" evtClick={this.evtClickSolicitar} />
                    <div></div>
                </div>
            </div>
        )
    }

}

export default Ordenes;