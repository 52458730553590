import React from "react";
import './../css/Olvido.css';
import MiBoton from "./MiBoton";
import Wait from "./Wait";
import Mensaje from "./Mensaje";
import Pregunta from './Pregunta';


class Olvido extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            socio: "",
            cliente: 0,
            mensaje: "",
            clase_mensaje: "Error",
            procesando: false,
            procesoCerrarMensaje: null
        }
        this.puedeValidar = this.puedeValidar.bind(this);
        this.valida = this.valida.bind(this);
        this.evtClick = this.evtClick.bind(this);
        this.evtChange = this.evtChange.bind(this);
        this.evtCerrarMensaje = this.evtCerrarMensaje.bind(this)
        this.procesoCierre = this.procesoCierre.bind(this)
    }

    puedeValidar(){
        let puede = true;
        if (this.state.socio.length==0){
            puede = false;
            this.setState({
                mensaje: "Debe digitar su código de empleado/profesor",
                clase_mensaje: "Error"
            });
        }

        return puede;
    }

    evtClick(event){
        if (this.puedeValidar()){
            this.valida();
        }
    }

    evtChange(event){
        const target = event.target;
        const nombre = target.name;
        this.setState({
            [nombre]: target.value
        })
    }


    async valida(){
        this.setState({
            procesando: true
        })
            var socio = this.state.socio;

            socio = socio.replace(" ", "").replace(" ", "");
            if (socio.substr(0,1)=="P" || socio.substr(0,1)=="J"){
                socio = socio.substr(1, socio.length);
            }

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var miData = new URLSearchParams();
            miData.append("socio", socio);

            var requestOptions = {
                method: 'POST',
                body: miData,
                Headers: myHeaders,
                redirect: 'follow'
            };
            let url = "https://api.cooeprouasd.net/api/login/olvido";
            try {
                const response = await fetch(url, requestOptions);
                if (response.ok){
                    const data = await response.json();
                    if (data.respuesta=="S"){
                        this.setState({
                            mensaje: data.mensaje,
                            clase_mensaje: "Info",
                            procesoCerrarMensaje: this.procesoCierre
                        })

                    } else {
                        this.setState({
                            mensaje: data.mensaje,
                            clase_mensaje: "Error"
                        })
                        
                    }
                
            } else {
                this.setState({
                    mensaje: "Ha ocurrido un error de conexión. Por favor intente más tarde"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Ha ocurrido un error: " + error
            })
          }
          this.setState({
              procesando: false
          })
        
    }
    evtCerrarMensaje(event){
        this.setState({
            mensaje: ""
        })
        if (this.state.procesoCerrarMensaje!=null){
            this.state.procesoCerrarMensaje();
        }
    }


    procesoCierre(){
        this.props.procesoCierre();
    }

    render(){
        const obj_wait = (this.state.procesando?<Wait />: null);
        const obj_mensaje = (this.state.mensaje.length>0?<Mensaje texto={this.state.mensaje} evtCerrar={this.evtCerrarMensaje} clase={this.state.clase_mensaje} miNombre={"mensaje"} />: null);

        return(
            <div className="Fondo-Olvido">
                {obj_wait}
                {obj_mensaje}
                <div className="Div-Olvido fade-in-olv">
                    <div><h4 style={{marginBottom: '60px', textAlign: 'center'}}>Para recuperar su contraseña,<br />por favor introduzca su código de profesor o empleado</h4></div>
                    <div className="RowOlv">
                        <div style={{alignSelf: 'center', justifySelf: 'right'}}>
                            <label className="col-form">Código:</label>
                        </div>
                        <div>
                            <input type="text" id="socio" className="form-control form-control-sm " autocomplete="off" name="socio" value={this.state.socio} onChange={this.evtChange} style={{maxWidth: '200px'}} />
                        </div>
                    </div>
                    <div className="misRows" style={{gridTemplateColumns: '1fr 150px 1fr'}}>
                        <div></div>
                        <MiBoton texto="Ok" evtClick={this.evtClick} />
                        <div></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Olvido;