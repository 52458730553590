import React from 'react';
import './../css/MiSelect.css';

class MiSelect extends React.Component{
    constructor(props){
        super(props);
        this.state = {
             disabled: ''
        }
        this.evtCambios = this.evtCambios.bind(this);
    }

    evtCambios(event){
        this.props.evtCambios(event);
    }

    componentDidUpdate(){
        

    }


    render(){
        const datos = this.props.filas;
        const inicio = <option value='0'>(Seleccione)</option>;
        const filas = datos.map((elem)=>{
            if (elem.padre==this.props.padre){
                return(<option value={elem.codigo}>{elem.nombre}</option>)
            }
            
        });
        return(
            <div style={{paddingTop: '4px', maxWidth: this.props.ancho}}>
                <select onChange={this.evtCambios} value={this.props.valor} name={this.props.miNombre} class="form-select form-select-sm " disabled={this.props.disabled}>
                    {inicio}
                    {filas}
                </select>
            </div>
        )
    }
}

export default MiSelect;