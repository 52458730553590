import React from 'react';
import './../css/MiDialog.css';
import DerechoOrden from './DerechoOrden';
import DerechoFeria from './DerechoFeria';
import DerechoPrestamos from './DerechoPrestamos';
import Gerenciales from './Gerenciales';
import Ordenes from './Ordenes';
import EstadoGeneral from './EstadoGeneral';
import EstadoDetallado from './EstadoDetallado';
import DetalleDescuentos from './DetalleDescuentos';
import Recargas from './Recargas';
import AhorrosRetiro from './AhorrosRetiro';
import AhorrosModifica from './AhorrosModifica';
import CasasComerciales from './CasasComerciales';
import Prestamos from './Prestamos';
import Calculadora from './Calculadora';
import DetallePrestamos from './DetallePrestamos';
import Actualiza from './Actualiza';
import AplicarVolante from './AplicarVolante';
import Stream from "./Stream";

class MiDialog extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            visible: "fade-in-dialog",
            titulo: ""
        }
        this.cerrarDialogo = this.cerrarDialogo.bind(this);
        this.cambiaTitulo = this.cambiaTitulo.bind(this);
        this.props.chequeo();
    }   

    cerrarDialogo(event){
        this.setState({
            visible: "fade-out-dialog"
        })
        setTimeout(()=>this.procesosCierre(), 400);
    }

    procesosCierre(event){
        this.props.evtCerrar();
        this.setState({visible: "fade-in-dialog"})
    }

    
    componentDidMount(){
        
    }

    cambiaTitulo(texto){
        this.setState({
            titulo: texto
        })
    }

    render() {
        //this.props.chequeo();
        var contenido;
        switch (this.props.proceso){
            case 1:
                contenido = <Ordenes procesoCierre={this.cerrarDialogo} titulo={this.cambiaTitulo} sesion_expirada={this.props.sesion_expirada}/>
                break;
            case 2:
                contenido = <Gerenciales procesoCierre={this.cerrarDialogo} titulo={this.cambiaTitulo} sesion_expirada={this.props.sesion_expirada}/>
                break;
            case 3:
                contenido = <DerechoOrden procesoCierre={this.cerrarDialogo} titulo={this.cambiaTitulo} sesion_expirada={this.props.sesion_expirada}/>
                break;
            case 4:
                contenido = <DerechoPrestamos procesoCierre={this.cerrarDialogo} titulo={this.cambiaTitulo} sesion_expirada={this.props.sesion_expirada}/>
                break;
            case 5:
                contenido = <EstadoGeneral titulo={this.cambiaTitulo} sesion_expirada={this.props.sesion_expirada}/>
                break;
            case 6:
                contenido = <EstadoDetallado titulo={this.cambiaTitulo} sesion_expirada={this.props.sesion_expirada}/>
                break;
            case 7:
                contenido = <DetalleDescuentos titulo={this.cambiaTitulo} sesion_expirada={this.props.sesion_expirada}/>
                break;
            case 8:
                contenido = <Recargas titulo={this.cambiaTitulo} procesoCierre={this.cerrarDialogo} sesion_expirada={this.props.sesion_expirada}/>
                break;
            case 9:
                contenido = <DerechoFeria procesoCierre={this.cerrarDialogo} titulo={this.cambiaTitulo} sesion_expirada={this.props.sesion_expirada}/>
                break;
            case 10:
                contenido = <AhorrosRetiro titulo={this.cambiaTitulo} procesoCierre={this.cerrarDialogo} sesion_expirada={this.props.sesion_expirada}/>
                break;
            case 11:
                contenido = <AhorrosModifica titulo={this.cambiaTitulo} procesoCierre={this.cerrarDialogo} sesion_expirada={this.props.sesion_expirada}/>
                break;
            case 12:
                contenido = <CasasComerciales titulo={this.cambiaTitulo} sesion_expirada={this.props.sesion_expirada}/>
                break;
            case 13:
                contenido = <Prestamos procesoCierre={this.cerrarDialogo} titulo={this.cambiaTitulo} sesion_expirada={this.props.sesion_expirada}/>
                break;
            case 14:
                contenido = <Calculadora procesoCierre={this.cerrarDialogo} titulo={this.cambiaTitulo} sesion_expirada={this.props.sesion_expirada}/>
                break;
            case 15:
                contenido = <DetallePrestamos procesoCierre={this.cerrarDialogo} titulo={this.cambiaTitulo} sesion_expirada={this.props.sesion_expirada}/>
                break;
            case 16:
                contenido = <Actualiza procesoCierre={this.cerrarDialogo} titulo={this.cambiaTitulo} sesion_expirada={this.props.sesion_expirada}/>
                break;
            case 18:
                contenido = <AplicarVolante procesoCierre={this.cerrarDialogo} titulo={this.cambiaTitulo} sesion_expirada={this.props.sesion_expirada}/>
                break;
            case 19:
                contenido = <Stream link_stream={this.props.link_stream} procesoCierre={this.cerrarDialogo} titulo={this.cambiaTitulo} sesion_expirada={this.props.sesion_expirada}/>
                break;
            }
        
        return(
            <div className="Dialog-fondo" style={{display: (this.props.visible=='S'?'block':'none')}} >
                <div id="Div-dialogo" className={(this.props.visible=="S"?this.state.visible:"")}>
                    <div className="boton-cerrar" onClick={this.cerrarDialogo}>X</div>
                    <div id="Dialog-titulo">{this.state.titulo}</div>
                    <div id="Dialog-contenido">{contenido}</div>
                </div>
            </div>
            
        )
    }
}

export default MiDialog;