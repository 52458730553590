import React from "react";
import './../css/DerechoOrden.css';
import Wait from "./Wait";

class DerechoOrden extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            derecho: "",
            procesando: true
        }
        this.inicio = this.inicio.bind(this);
        this.inicio();
    }

    async inicio(){
        this.props.titulo("Mi derecho a solicitar órdenes");
        await this.actualiza();
    }

    async actualiza(){
        let tk = "";
        try{
            tk = localStorage.getItem("tk");
        } catch(error){

        }
        var myHeaders = new Headers();
        myHeaders.append("tk", tk);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        

        var miData = new URLSearchParams();
        miData.append("cliente", localStorage.getItem("cliente"));

        var requestOptions = {
            method: 'POST',
            body: miData,
            headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/derecho/ordenes";
          try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();



                let txt = "";
                if (data.respuesta=="N"){
                    txt = "Actualmente estamos imposibilitados de atender su peticion, por las razones siguientes:" + data.mensaje
                } else {
                    txt = data.mensaje2;
                    
                }


                this.setState({
                    derecho: txt,
                    procesando: false
                })
                
            } else {
                
            }
          } catch(error){
            //alert("Error: " + error);
          }
          this.setState({
              procesando: false
          })
    }

    render(){
        const obj_wait = (this.state.procesando?<Wait />:null);
        return(
            <div className="Fondo-Derecho">
                {obj_wait}
                <div className="Div-Derecho">
                    {this.state.derecho}
                </div>
            </div>
        )
    }
}

export default DerechoOrden;