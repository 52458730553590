import React from 'react';
import './../css/Pregunta.css';
import Mensaje from './Mensaje';
import MiBoton from './MiBoton';
import Wait from './Wait';

class Pregunta extends React.Component {
    constructor(props){
        super(props);
        var cliente = this.props.cliente || localStorage.getItem("cliente");
        this.state = {
            visible: "fade-in-preg",
            cliente: cliente,
            pregunta: 0,
            pregunta_texto: '',
            respuesta: '',
            buscandoDatos: true,
            mensaje: '',
            clase_mensaje: 'Error'
        }
        this.buscaPregunta = this.buscaPregunta.bind(this);
        this.procesoCierre = this.procesoCierre.bind(this);
        this.evtChange = this.evtChange.bind(this);
        this.evtClick = this.evtClick.bind(this);
        this.respuesta_correcta = this.respuesta_correcta.bind(this);
        this.evtCerrarMensaje = this.evtCerrarMensaje.bind(this);
        this.puedeRevisar = this.puedeRevisar.bind(this);

        this.buscaPregunta();
    }

    evtChange(event){
        const target = event.target;
        const nombre = target.name;
        this.setState({
            [nombre]: target.value
        })
    }



    procesoCierre(){
        this.props.procesoCierre();
    }


    async buscaPregunta(){
        var resultado = false;
        this.setState({
            buscandoDatos: true
        })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var miData = new URLSearchParams();
        miData.append("cliente", localStorage.getItem("cliente"));
        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
          try {
            const response = await fetch("https://api.cooeprouasd.net/api/login/pregunta", requestOptions);
            if (response.ok){
                const data = await response.json();
                if (data.respuesta=="S"){
                    resultado = true;
                    this.setState({
                        pregunta: data.pregunta,
                        pregunta_texto: data.texto
                    })
                } else {
                    this.setState({
                        mensaje: data.mensaje,
                        clase_mensaje: "Error"
                    })
                    
                }
            } else {
                this.setState({
                    mensaje: "Ha ocurrido un error de conexión. Por favor intente más tarde",
                    clase_mensaje: "Error"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Error: " + error,
                clase_mensaje: "Error"
            })
          }
          this.setState({
              buscandoDatos: false
          })
        return resultado;
    }

    evtClick(event){
        if (this.puedeRevisar()){
            this.revisaRespuesta();
        }
    }

    puedeRevisar(){
        let puede = true;
        if (this.state.respuesta.length==0){
            puede = false;
            this.setState({
                mensaje: "Debe responder la pregunta"
            })
        }
        return puede;
    }

    respuesta_correcta(){
        this.setState({
            visible: "fade-out-preg",

        })
        this.props.respuesta_correcta();
    }

    async revisaRespuesta(){
        var resultado = false;
        this.setState({
            buscandoDatos: true
        })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var miData = new URLSearchParams();
        miData.append("cliente", this.state.cliente);
        miData.append("pregunta", this.state.pregunta);
        miData.append("respuesta", this.state.respuesta);
        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
          try {
            const response = await fetch("https://api.cooeprouasd.net/api/login/respuesta", requestOptions);
            if (response.ok){
                const data = await response.json();
                if (data.respuesta=="S"){
                    resultado = true;
                    this.respuesta_correcta();
                } else {
                    this.setState({
                        mensaje: data.mensaje,
                        clase_mensaje: "Error"
                    })
                    
                }
            } else {
                this.setState({
                    mensaje: "Ha ocurrido un error de conexión. Por favor intente más tarde",
                    clase_mensaje: "Error"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Error: " + error,
                clase_mensaje: "Error"
            })
          }
          this.setState({
              buscandoDatos: false
          })
        return resultado;
    }

    evtCerrarMensaje(){
        this.setState({
            mensaje: ""
        })
    }

    render(){
        const obj_wait = (this.state.buscandoDatos?<Wait />:null);
        const obj_mensaje = (this.state.mensaje.length>0 ? <Mensaje clase={this.state.clase_mensaje} texto={this.state.mensaje} evtCerrar={this.evtCerrarMensaje} />:null);
        return(
            <div className="Fondo-Pregunta" >
                <div className={"Div-Pregunta " + this.state.visible}  >
                    {obj_wait}
                    {obj_mensaje}
                <div className="boton-cerrar" onClick={this.procesoCierre}>X</div>
                    <div className="Div-Pregunta-Titulo">
                        <div className="Div-Pregunta-Titulo-Icon"></div>
                        <div>Por favor responda la siguiente pregunta...</div>
                    </div>
                    <div className="Div-Pregunta-Texto">{"¿" + this.state.pregunta_texto + "?"}</div>
                    <div>
                        <textarea name="respuesta" value={this.state.respuesta} onChange={this.evtChange}  className="form-control" rows="2"></textarea>
                    </div>
                    <div className="Div-Pregunta-Boton">
                        <div></div>
                        <MiBoton  texto="Ok" miNombre="btConfirmarOk" evtClick={this.evtClick} />
                        <div></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Pregunta;