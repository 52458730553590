import React from 'react';

import icon_view from './../images/view.png';
import icon_tr from './../images/transacc.png';
import icon_config from './../images/config.png';

import './../css/MenuLeft.css';

class MenuLeft extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            hay_feria: false
        }
        this.evtClick = this.evtClick.bind(this);
        this.cerrarSesion = this.cerrarSesion.bind(this);
    }


    evtClick(event){
        var esPadre = false;
        var hijos;
        const id = event.currentTarget.getAttribute('id');
        if (id!=null){
            document.getElementById(id).classList.toggle("select")
            hijos = document.querySelectorAll(".menu_opciones[data-padre='" + id + "']")
            if (hijos.length>0){
                esPadre = true;
                hijos.forEach((elem)=> {
                    elem.classList.toggle("visible");
                });
            }
        }
        if (!esPadre){
            let idProceso = parseInt(event.currentTarget.getAttribute("data-menu"));
            this.props.evtClick(idProceso);
        }


        
        
        
      }

    cerrarSesion(){
        try {
            window.Android.CierraSesion();
        } catch(error){

        }
        this.props.cierraSesion();
    }

    render() {
        const obj_feria = (this.state.hay_feria?<div className="anuncios" data-menu="9" onClick={this.evtClick}><div className="anuncios_img"></div><div className="anuncios_texto">{"¿A qué tengo derecho en la Feria?"}</div></div>: null);
        const mn = (1==1?
        <div class="menu_opciones nivel1 visible" data-padre="mn_tr" data-menu="18" onClick={this.evtClick}>
                    <div></div>
                    <div class="menu_opciones_texto">Aplicar mi Depósito</div>
                    <div class="menu_opciones_mas"></div>
                </div>
        : null);
        return(
            <div id="menu_left_opciones">
                {obj_feria}
                <div className="menu_padres menu_opciones select" id="mn_view"  onClick={this.evtClick}>
                    <div className="menu_opciones_icon"><img src={icon_view} /></div>
                    <div className="menu_opciones_texto">Consultas</div>
                    <div className="menu_opciones_mas">{">"}</div>
                </div>
                <div class="menu_opciones nivel1 visible" data-padre="mn_view" id="mn_der"  onClick={this.evtClick}>
                    <div></div>
                    <div class="menu_opciones_texto">{"¿A qué tengo derecho?"}</div>
                    <div class="menu_opciones_mas">{">"}</div>
                </div>
                <div class="menu_opciones nivel2 " data-padre="mn_der" data-menu="3"  onClick={this.evtClick}>
                    <div></div>
                    <div class="menu_opciones_texto">{"En Ordenes"}</div>
                    <div class="menu_opciones_mas"></div>
                </div>
                <div class="menu_opciones nivel2 " data-padre="mn_der" data-menu="4"  onClick={this.evtClick}>
                    <div></div>
                    <div class="menu_opciones_texto">{"En Préstamos"}</div>
                    <div class="menu_opciones_mas"></div>
                </div>

                <div class="menu_opciones nivel1 visible" data-padre="mn_view" data-menu="14" onClick={this.evtClick}>
                    <div></div>
                    <div class="menu_opciones_texto">{"Calculadora de Préstamos"}</div>
                    <div class="menu_opciones_mas"></div>
                </div>
                <div class="menu_opciones nivel1 visible" data-padre="mn_view" data-menu="5" onClick={this.evtClick}>
                    <div></div>
                    <div class="menu_opciones_texto">{"Estado de Cuenta General"}</div>
                    <div class="menu_opciones_mas"></div>
                </div>
                <div class="menu_opciones nivel1 visible" data-padre="mn_view" data-menu="6" onClick={this.evtClick}>
                    <div></div>
                    <div class="menu_opciones_texto">{"Estado Ahorro Detallado"}</div>
                    <div class="menu_opciones_mas"></div>
                </div>
                <div class="menu_opciones nivel1 visible" data-padre="mn_view" data-menu="7" onClick={this.evtClick}>
                    <div></div>
                    <div class="menu_opciones_texto">{"Detalle de Descuentos"}</div>
                    <div class="menu_opciones_mas"></div>
                </div>
                <div class="menu_opciones nivel1 visible" data-padre="mn_view" data-menu="15" onClick={this.evtClick}>
                    <div></div>
                    <div class="menu_opciones_texto">{"Detalle Préstamos"}</div>
                    <div class="menu_opciones_mas"></div>
                </div>
                <div class="menu_opciones nivel1 visible" data-padre="mn_view" data-menu="12" onClick={this.evtClick}>
                    <div></div>
                    <div class="menu_opciones_texto">{"Listado Suplidores"}</div>
                    <div class="menu_opciones_mas"></div>
                </div>


                <div class="menu_padres menu_opciones select" id="mn_tr" onClick={this.evtClick}>
                    <div class="menu_opciones_icon"><img src={icon_tr} /></div>
                    <div class="menu_opciones_texto">Transacciones</div>
                    <div class="menu_opciones_mas">{">"}</div>
                </div>
                <div class="menu_opciones nivel1 visible" data-padre="mn_tr" data-menu="1" onClick={this.evtClick}>
                    <div></div>
                    <div class="menu_opciones_texto">Solicitud de Orden</div>
                    <div class="menu_opciones_mas"></div>
                </div>
                <div class="menu_opciones nivel1 visible" data-padre="mn_tr" id="sol_pr" onClick={this.evtClick}>
                    <div></div>
                    <div class="menu_opciones_texto">Solicitud de Préstamo</div>
                    <div class="menu_opciones_mas">{">"}</div>
                </div>
                <div class="menu_opciones nivel2 " data-padre="sol_pr" data-menu="2"  onClick={this.evtClick} style={{marginBottom: '0px'}}>
                    <div></div>
                    <div class="menu_opciones_texto">Gerencial</div>
                    <div class="menu_opciones_mas"></div>
                </div>
                <div class="menu_opciones nivel2 " data-padre="sol_pr" data-menu="13"  onClick={this.evtClick} >
                    <div></div>
                    <div class="menu_opciones_texto">Expreso, Normal o Especial</div>
                    <div class="menu_opciones_mas"></div>
                </div>
                <div class="menu_opciones nivel1 visible" data-padre="mn_tr" data-menu="10" onClick={this.evtClick}>
                    <div></div>
                    <div class="menu_opciones_texto">Retiro Ahorros</div>
                    <div class="menu_opciones_mas"></div>
                </div>
                <div class="menu_opciones nivel1 visible" data-padre="mn_tr" data-menu="11" onClick={this.evtClick}>
                    <div></div>
                    <div class="menu_opciones_texto">Modificación Ahorros</div>
                    <div class="menu_opciones_mas"></div>
                </div>
                <div class="menu_opciones nivel1 visible" data-padre="mn_tr" data-menu="8" onClick={this.evtClick}>
                    <div></div>
                    <div class="menu_opciones_texto">Recargas</div>
                    <div class="menu_opciones_mas"></div>
                </div>

                {mn}


                <div class="menu_padres menu_opciones " id="mn_config" onClick={this.evtClick}>
                    <div class="menu_opciones_icon"><img src={icon_config} /></div>
                    <div class="menu_opciones_texto">Configuración</div>
                    <div class="menu_opciones_mas">{">"}</div>
                </div>
                <div class="menu_opciones nivel1 " data-padre="mn_config" data-menu="17" onClick={this.evtClick}>
                    <div></div>
                    <div class="menu_opciones_texto">Mi Whatsapp</div>
                    <div class="menu_opciones_mas"></div>
                </div>
                <div class="menu_opciones nivel1 " data-padre="mn_config" data-menu="16" onClick={this.evtClick}>
                    <div></div>
                    <div class="menu_opciones_texto">Actualizar mis datos</div>
                    <div class="menu_opciones_mas"></div>
                </div>
                <div class="menu_opciones nivel1 " data-padre="mn_config" data-menu="99" onClick={this.evtClick}>
                    <div></div>
                    <div class="menu_opciones_texto">Cambiar mi contraseña</div>
                    <div class="menu_opciones_mas"></div>
                </div>
                <div class="menu_opciones nivel1 " data-padre="mn_config"  onClick={this.cerrarSesion}>
                    <div></div>
                    <div class="menu_opciones_texto">Cerrar la sesión</div>
                    <div class="menu_opciones_mas"></div>
                </div>
          </div>
        )
    }
}

export default MenuLeft;