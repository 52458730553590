import React from "react";
import './../css/Gerenciales.css';
import MiInfoValor from './MiInfoValor';
import MiEdit from "./MiEdit";
import MiBoton from "./MiBoton";
import Wait from "./Wait";
import Mensaje from "./Mensaje";
import Confirmacion from "./Confirmacion";
import Pregunta from "./Pregunta";


class Gerenciales extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            monto: '0.00',
            derecho: 0.00,
            tasa: 0,
            balance: 0.00,
            info: '',
            mensaje: '',
            inicio: '',
            clase_mensaje: "Error",
            confirma_gerencial: false,
            buscandoDatos: true,
            pregunta_seguridad: true
        }
        this.inicio = this.inicio.bind(this);
        this.inicioGerencial = this.inicioGerencial.bind(this);
        this.evtChange = this.evtChange.bind(this);
        this.verificaGerencial = this.verificaGerencial.bind(this);
        this.evtSolicita = this.evtSolicita.bind(this);
        this.evtCerrarMensaje = this.evtCerrarMensaje.bind(this);
        this.evtCerrarConf = this.evtCerrarConf.bind(this);
        this.evtClickConfirmacion = this.evtClickConfirmacion.bind(this);
        this.grabaGerencial = this.grabaGerencial.bind(this);
        this.procesoCierre = this.procesoCierre.bind(this);
        this.respuesta_correcta = this.respuesta_correcta.bind(this);

        this.inicio();
    }

    async inicio(){
        this.props.titulo("Solicitud Préstamo Gerencial");
        await this.inicioGerencial();
    }

    async inicioGerencial(){
        var resultado = false;
        this.setState({
            buscandoDatos: true
        })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var miData = new URLSearchParams();
        miData.append("cliente", localStorage.getItem("cliente"));
        miData.append("monto", this.state.monto);
        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
          try {
            const response = await fetch("https://api.cooeprouasd.net/api/gerenciales/puede", requestOptions);
            if (response.ok){
                const data = await response.json();
                if (data.respuesta=="S"){
                    resultado = true;
                    this.setState({
                        derecho: parseFloat(data.maximo),
                        tasa: parseFloat(data.tasa),
                        balance: parseFloat(data.balance),
                        inicio: data.inicio
                    })
                } else {
                    this.setState({
                        mensaje: data.mensaje,
                        clase_mensaje: "Error"
                    })
                    
                }
            } else {
                this.setState({
                    mensaje: "Ha ocurrido un error de conexión. Por favor intente más tarde",
                    clase_mensaje: "Error"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Error: " + error,
                clase_mensaje: "Error"
            })
          }
          this.setState({
              buscandoDatos: false
          })
        return resultado;
    }

    evtChange(event){
        const target = event.target;
        const nombre = target.name;
        this.setState({
            [nombre]: target.value
        })
        
    }

    async evtSolicita(event){
        if (this.verificaGerencial()){
            let puede = await this.inicioGerencial();
            if (puede){
                this.setState({
                    confirma_gerencial: true
                })
            }
        }
    }

    verificaGerencial(){
        let puede = false;
        let monto = this.state.monto;
        let maximo = this.state.derecho;
        let tasa = this.state.tasa;
        let balance = this.state.balance;
        let mensaje = "";
        let info = "";

        if (monto > maximo) {
            mensaje = "Error. Monto solicitado mayor al límite permitido.";
        } else {
            if (monto > 0) {
                if (monto <= balance) {
                    mensaje = "Error. Monto solicitado es menor al balance actual: RD$" + balance.format(2, 3, ',', '.');
                } else {
                    if (monto <= 100) {
                        puede = false;
                        mensaje = "Error. Monto solicitado debe ser mayor de 100.00";
                    } else {
                        puede = true;
                        var mnt = 0.00;
                        var txt = "";
                        mnt = monto * (tasa / 100);
                        if (balance > 0) {
                            txt = "Se le descontará el balance de su préstamo anterior (RD$" + balance.format(2, 3, ',', '.') + ") y se le transferirán RD$" + (monto - balance).format(2, 3, ',', '.') + ". ";
                        }
                        txt = txt + "Pagará un interés de RD$" + mnt.format(2, 3, ',', '.') + ", a partir de " + this.state.inicio;
                        info = txt;
                    }
                }
            } else {
                mensaje = "Error. Debe digitar el monto del gerencial";
            }
        }
        if (!puede){
            this.setState({
                mensaje: mensaje,
                clase_mensaje: "Error"
            })
        } else {
            this.setState({
                info: info
            })
        }
        
        return puede;
    }

    evtCerrarMensaje(){
        this.setState({
            mensaje: ""
        })
        if (this.state.cierre_mensaje !=null){
            this.state.cierre_mensaje();
        }
    }

    procesoCierre(){
        this.props.procesoCierre();
    }
    evtCerrarConf(event){
        this.setState({
            confirma_gerencial: false
        })
    }

    async evtClickConfirmacion(event){
        if (this.verificaGerencial()){
            let puede = await this.inicioGerencial();
            if (puede){
                await this.grabaGerencial()
            }
        }
    }

    async grabaGerencial(){
        var resultado = false;
        this.setState({
            buscandoDatos: true
        })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var miData = new URLSearchParams();
        miData.append("cliente", localStorage.getItem("cliente"));
        miData.append("monto", this.state.monto);
        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
          try {
            const response = await fetch("https://api.cooeprouasd.net/api/gerenciales/graba", requestOptions);
            if (response.ok){
                const data = await response.json();
                if (data.respuesta=="S"){
                    resultado = true;
                    this.setState({
                        mensaje: "Su gerencial fue solicitado exitosamente.",
                        clase_mensaje: "Info",
                        cierre_mensaje: this.procesoCierre
                    })
                } else {
                    this.setState({
                        mensaje: data.mensaje,
                        clase_mensaje: "Error"
                    })
                    
                }
            } else {
                this.setState({
                    mensaje: "Ha ocurrido un error de conexión. Por favor intente más tarde",
                    clase_mensaje: "Error"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Error: " + error,
                clase_mensaje: "Error"
            })
          }
          this.setState({
              buscandoDatos: false
          })
        return resultado;
    }

    respuesta_correcta(){
        this.setState({
            pregunta_seguridad: false
        })
    }

    render(){
        const obj_wait = (this.state.buscandoDatos?<Wait />: null);
        const obj_mensaje = (this.state.mensaje.length>0 ? <Mensaje clase={this.state.clase_mensaje} texto={this.state.mensaje} evtCerrar={this.evtCerrarMensaje} />:null);
        const obj_confirmacion = (this.state.confirma_gerencial? <Confirmacion enEspera={this.state.buscandoDatos} texto={this.state.info} evtCerrar={this.evtCerrarConf} evtClick={this.evtClickConfirmacion} />:null);
        const obj_seguridad = (this.state.pregunta_seguridad? <Pregunta respuesta_correcta={this.respuesta_correcta} procesoCierre={this.procesoCierre} />: null);
        return(
            <div className="Div-Gerencial">
                {obj_wait}
                {obj_seguridad}
                {obj_mensaje}
                {obj_confirmacion}
                <div className="misRows" style={{gridTemplateColumns: '1fr 200px 1fr', marginBottom: '50px'}}>
                    <div></div>
                    <MiInfoValor valor={parseFloat(this.state.derecho).format(2,3,',','.')} label="Usted tiene derecho a..." />
                    <div></div>
                </div>
                <div className="rowOrd" style={{marginBottom: '40px'}}>
                    <div><label className='EtiqOrd grande'>Monto a solicitar:</label></div>
                    <MiEdit maximo={this.state.derecho} miNombre="monto" valor={this.state.monto} evtChange={this.evtChange} />
                </div>
                <div className="misRows" style={{gridTemplateColumns: '1fr 200px 1fr', marginTop: '10px'}}>
                    <div></div>
                    <MiBoton texto="Solicitar" miNombre="btSolicitar" evtClick={this.evtSolicita} />
                    <div></div>
                </div>
            </div>
        )
    }
}

export default Gerenciales;