import react from "react";
import "../css/Storys.css";
import Reel from "./Reel";

import { useEffect, useState } from "react";
import ReelView from "./ReelView";

export default function Storys(props) {
  const [rows, setRows] = useState([]);
  const [openView, setOpenView] = useState(false);
  const [viewToken, setViewToken] = useState("")

  useEffect(() => {
    actReels();
  }, []);

  const actReels = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var miData = new URLSearchParams();
    miData.append("casacomercial", 0);

    var requestOptions = {
      method: "POST",
      body: miData,
      Headers: myHeaders,
      redirect: "follow",
    };
    let url = "https://api.cooeprouasd.net/api/videos/lista2";
    try {
      const response = await fetch(url, requestOptions);

      if (response.ok) {
        const data = await response.json();
        if (data.respuesta == "S") {
          setRows(data.lista);
        }
      } else {
      }
    } catch (error) {}
  };

  const abreView = (token) => {
    if (token){
      setViewToken(token)
    }
    
    setOpenView(!openView);
  };

  const cambio = (token)=>{
    setViewToken(token)
  }


  return (
    <div className="barra">
      {
        openView?
        <ReelView close={abreView} token={viewToken} cambio={cambio}/>
        : null
      }
      <div className="barra-scroll">
        {rows.map((row) => (
          <Reel
            key={row.token}
            token={row.token}
            abre={()=>{abreView(row.token)}}
            titulo={row.nombre}
          />
        ))}
      </div>
    </div>
  );
}
