import React from "react";
import './../css/Whatsapp.css';
import Wait from "./Wait";
import MiEdit from "./MiEdit.jsx";
import MiBoton from "./MiBoton";
import Mensaje from "./Mensaje";

import ws from "../images/whatsapp.png";

class Whatsapp extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            procesando: false,
            celular: this.props.celular,
            mensaje: "",
            clase_mensaje: "Error",
            mensaje_proceso: null,
        }
        this.traePreguntas = this.traePreguntas.bind(this);
        this.evtClick = this.evtClick.bind(this);
        this.evtChangeCampos = this.evtChangeCampos.bind(this);
        this.puedeRegistrar = this.puedeRegistrar.bind(this);
        this.evtCerrarMensaje = this.evtCerrarMensaje.bind(this);
        this.evtCierre = this.evtCierre.bind(this);
        this.evtChange = this.evtChange.bind(this);
        this.evtClickNo = this.evtClickNo.bind(this);
        this.cerrarDialogo = this.cerrarDialogo.bind(this)
        //this.traePreguntas();
    }

    evtCierre(){
        this.props.cierre();
    }

    evtChange(event){
        const target = event.target;
        const nombre = target.name;
        this.setState({
            [nombre]: target.value
        });
       
    }

    async traePreguntas(){
        this.setState(
            {
                procesando: true
            });
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var miData = new URLSearchParams();
        miData.append("socio", this.props.sesion);

        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/login/segpreguntas";
        try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();
                if (data.respuesta=="S"){
                    const a_preguntas = JSON.parse(data.preguntas);
                    var a;
                    var datos = [];
                    let respuestas = [];
                    for(a=0;a<a_preguntas.length;a++){
                        let item = {};
                        item.id = a_preguntas[a].id;
                        item.columnas = [a_preguntas[a].texto, '[campo]:resp'];
                        let nuevo = {id: item.id, valor: ""}
                        respuestas.push(nuevo);
                        datos.push(item);
                    }
                    this.setState({
                        datos: datos,
                        respuestas: respuestas
                    })
                } else {
                    this.setState({
                        mensaje: data.mensaje,
                        mensaje_proceso: this.evtCierre
                    })
                }
            } else {
                this.setState({
                    mensaje: "Ha ocurrido un error de conexión, por favor intente más tarde"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Ha ocurrido un error de conexión, por favor intente más tarde"
            })
          }
          this.setState({
              procesando: false
          });
    }

    puedeRegistrar(){
        let puede = true;
        if (this.state.celular.length!=10){
            puede = false;
            this.setState({
                mensaje: "Debe digitar su celular",
                mensaje_proceso: null
            })
        }
        return puede;
    }

    async grabaRespuesta(estatus){
        let cliente = parseInt(localStorage.getItem("cliente"));
        this.setState(
            {
                procesando: true
            });
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var miData = new URLSearchParams();
        miData.append("cliente", cliente);
        miData.append("celular", estatus==1?this.state.celular:"0");
        miData.append("estatus", estatus);
        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/login/whatsapp_sol";
        try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();
                if (data.respuesta=="S"){

                        this.setState({
                            mensaje: "Gracias por su información",
                            clase_mensaje: "Info",
                            mensaje_proceso: this.evtCierre
                        })
                        
                } else {
                    if (data.respuesta == "X"){
                        this.setState({
                            mensaje: data.mensaje,
                            mensaje_proceso: null
                        })
                    } else {
                        this.setState({
                            mensaje: data.mensaje,
                            mensaje_proceso: this.evtCierre
                        })
                    }
                    
                }
            } else {
                this.setState({
                    mensaje: "Ha ocurrido un error de conexión, por favor intente más tarde"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Ha ocurrido un error de conexión, por favor intente más tarde"
            })
          }
          this.setState({
              procesando: false
          });
    }

    evtClick(event){
        if (this.puedeRegistrar()){
            this.grabaRespuesta(1);
        }
    }


    evtClickNo(event){
        
        this.grabaRespuesta(2)
    }
    evtChangeCampos(campo, valor){
        var a;
        let respuestas = this.state.respuestas;
        let id = campo.substr(5, campo.length-4)
        for(a=0;a<respuestas.length;a++){
            if (respuestas[a].id==id){
                respuestas[a].valor = valor;
                break;
            }
        }
        this.setState({
            respuestas: respuestas
        })
    }

    evtCerrarMensaje(){
        this.setState({
            mensaje: ""
        })
        if (this.state.mensaje_proceso!=null){
            this.state.mensaje_proceso();
        }
    }

    cerrarDialogo(){
        this.props.cierre();
    }

    render(){
        const obj_wait = (this.state.procesando? <Wait />: null);
        const obj_mensaje = (this.state.mensaje.length>0?<Mensaje texto={this.state.mensaje} clase={this.state.clase_mensaje} evtCerrar={this.evtCerrarMensaje} />: null);
        return(
            <div className="Fondo-WS">
                {obj_wait}
                {obj_mensaje}
                <div className="Div-WS">
                    <div className="boton-cerrar-ws" onClick={this.cerrarDialogo}>X</div>
                    <div className="misRows" style={{gridTemplateColumns: "100px 1fr", paddingBottom: "10px", marginBottom: "20px", borderBottom: "1px solid black"}}>
                        <div><img src={ws} className="ws-logo"/></div>
                        <div className="ws-tit">Queremos estar más<br />cerca de Usted</div>
                    </div>
                    <div className="ws-msg">
                        Por favor, suminístrenos su número de Whatsapp, para poder utilizarlo como medio de contacto
                    </div>
                    <div className="misRows" style={{gridTemplateColumns: "1fr 200px 1fr", marginBottom: "20px"}}>
                        <div></div>
                        <div><input className="form-control ws-celular" type="number" name="celular" value={this.state.celular} onChange={this.evtChange} /></div>
                        <div></div>
                    </div>
                    <div className="WS-Boton">
                        <div><MiBoton texto="OK" evtClick={this.evtClick} /></div>
                        <div></div>
                        <div><MiBoton texto="No quiero" evtClick={this.evtClickNo} /></div>
                    </div>
                    
                </div>
            </div>
        )
    }
}

export default Whatsapp;