import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Backdrop,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
import "../css/Campamento.css";
import MiBoton from "./MiBoton";
import Confirmacion from "./Confirmacion";
import Mensaje from "./Mensaje";

export default function Campamento(props) {
  const [rows, setRows] = useState([]);
  const [mensaje, setMensaje] = useState("");
  const [procesando, setProcesando] = useState(false);
  const [openConfirmacion, setOpenConfirmacion] = useState(false);

  useEffect(() => {
    actRows();
  }, []);

  const actRows = async () => {
    

    setProcesando(true);
    let tk = "";
    try {
      tk = localStorage.getItem("tk");
    } catch (error) {}

    var myHeaders = new Headers();
    myHeaders.append("tk", tk);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var miData = new URLSearchParams();
    miData.append("cliente", localStorage.getItem("cliente"));

    var requestOptions = {
      method: "POST",
      body: miData,
      headers: myHeaders,
      redirect: "follow",
    };
    let url = "https://api.cooeprouasd.net/api/campamento/inicio";
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        const data = await response.json();
        if (data.respuesta == "S") {
          let num = 0;
          let newR = data.lista.map((row) => {
            num++;
            return {
              ...row,
              num: num,
              id: num
            };
          });
          setRows(newR);
        } else {
          setMensaje(data.mensaje);
        }
      } else {
        setMensaje(
          "Ha ocurrido un error de conexión. Por favor intente más tarde"
        );
      }
    } catch (error) {
      setMensaje("Error: " + error);
    }
    setProcesando(false);
  };

  useEffect(() => {
    console.log(mensaje);
  }, [mensaje]);

  const handleChange = (id, campo, valor) => {
    const newR = rows.map((row) => {
      if (row.id == id) {
        return {
          ...row,
          [campo]: valor,
        };
      } else {
        return row;
      }
    });
    setRows(newR);
  };
  const handleCerrar = () => {
    props.cerrar();
  };

  const objWait = () => {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={procesando}
        onClick={() => {
          setProcesando(false);
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const puedeGrabar = () => {
    let resultado = true;
    if (rows.filter((row) => row.nombre.length > 0).length == 0) {
      setMensaje("Debe digitar los datos de sus hijos!");
      resultado = false;
    }

    rows
      .filter((row) => row.nombre.length > 0)
      .forEach((row) => {
        if (row.nacimiento == null) {
          setMensaje("Falta la fecha de nacimiento del niño(a) " + row.nombre);
          resultado = false;
          return false;
        } else {
          let fecha = new Date(row.nacimiento + "T00:00:00");
          let hoy = new Date();
          var diff = (hoy.getTime() - fecha.getTime()) / 1000;
          diff /= 60 * 60 * 24;
          let edad = Math.abs(Math.round(diff / 365.25));
          console.log("la edad es ", edad);
          if (!(edad >= 6 && edad <= 13)) {
            setMensaje(
              row.nombre +
                " tiene " +
                edad +
                " años. La edad debe ser entre los 6 y los 13 años"
            );
            resultado = false;
            return false;
          }
        }
        if (row.size == 0) {
          setMensaje("Falta el size de camiseta del niño(a) " + row.nombre);
          resultado = false;
          return false;
        }
      });

    return resultado;
  };

  const handleGrabar = () => {
    if (puedeGrabar()) {
      setOpenConfirmacion(true);
    }
  };

  const graba = async () => {
    setProcesando(true);
    let tk = "";
    try {
      tk = localStorage.getItem("tk");
    } catch (error) {}
    const newR = rows.filter((row) => row.nombre.length > 0).map((row) => row);

    var myHeaders = new Headers();
    myHeaders.append("tk", tk);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var miData = new URLSearchParams();
    miData.append("cliente", localStorage.getItem("cliente"));
    miData.append("hijos", JSON.stringify(newR));

    var requestOptions = {
      method: "POST",
      body: miData,
      headers: myHeaders,
      redirect: "follow",
    };
    let url = "https://api.cooeprouasd.net/api/campamento/grabar";
    try {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        const data = await response.json();
        if (data.respuesta == "S") {
          var a;
          setMensaje("Su inscripción ha sido procesada exitosamente!");
          props.cerrar();
        } else {
          setMensaje(data.mensaje);
        }
      } else {
        setMensaje(
          "Ha ocurrido un error de conexión. Por favor intente más tarde"
        );
      }
    } catch (error) {
      setMensaje("Error: " + error);
    }
    setProcesando(false);
  };

  const objConfirmacion = () => {
    if (openConfirmacion) {
      return (
        <Confirmacion
          texto="¿Procesar la Inscripción?"
          evtClick={() => {
            setOpenConfirmacion(false);
            graba();
          }}
          evtCerrar={() => {
            setOpenConfirmacion(false);
          }}
        />
      );
    } else {
      return null;
    }
  };

  const objMensaje = () => {
    if (mensaje.length == 0) {
      return null;
    }
    return (
      <Mensaje
        texto={mensaje}
        evtCerrar={() => {
          setMensaje("");
        }}
      />
    );
  };

  return (
    <div className="camp-cuerpo">
      {objWait()}
      {objConfirmacion()}
      {objMensaje()}
      <div className="boton-cerrar" onClick={handleCerrar}>
        X
      </div>
      <div className="camp-titulo">Campamento Infantil 2024</div>
      <div className="camp-mensaje-a">Formulario de Inscripción</div>
      <div className="camp-mensaje">
        Complete las siguientes informaciones de los niños a inscribir:
        <br />
        * Nombre * Fecha Nacimiento * Sexo * Size en camisetas
        <br />* ¿Posée alguna enfermedad? * ¿Posée alguna alergia?
      </div>
      <div className="camp-body">
        <div className="camp-rows head">
          <div>#</div>
          <div>Nombre</div>
          <div>F. Nacimiento</div>
          <div>Sexo</div>
          <div>Size</div>
          <div>¿Posée alguna enfermedad?</div>
          <div>¿Posée alguna alergia?</div>
        </div>
        {rows.map((row) => {
          return (
            <div className="camp-rows">
              <div className="camp-row-id">{row.num}</div>
              <div className="camp-row-nombre">
                <TextField
                  fullWidth
                  size="small"
                  autoComplete="off"
                  value={row.nombre}
                  label={"Nombre"}
                  type="text"
                  onChange={(event) => {
                    handleChange(
                      row.id,
                      "nombre",
                      event.target.value.toUpperCase()
                    );
                  }}
                />
              </div>
              <div className="camp-row-nacimiento">
                <TextField
                  fullWidth
                  size="small"
                  autoComplete="off"
                  value={row.nacimiento}
                  type="date"
                  onChange={(event) => {
                    handleChange(
                      row.id,
                      "nacimiento",
                      event.target.value.toUpperCase()
                    );
                  }}
                />
              </div>
              <div
                style={{ display: "flex", justifyContent: "center" }}
                className="camp-row-sexo"
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="sexo"
                    name="sexo"
                    value={row.sexo}
                    onChange={(event) => {
                      handleChange(
                        row.id,
                        "sexo",
                        event.target.value.toUpperCase()
                      );
                    }}
                  >
                    <FormControlLabel
                      value="M"
                      control={<Radio size="small" />}
                      label="M"
                    />
                    <FormControlLabel
                      value="F"
                      control={<Radio size="small" />}
                      label="F"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="camp-row-size">
                <FormControl size="small" fullWidth>
                  <Select
                    value={row.size}
                    fullWidth
                    onChange={(event) => {
                      handleChange(row.id, "size", event.target.value);
                    }}
                  >
                    <MenuItem value={0}>
                      <em>(Size)</em>
                    </MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={12}>12</MenuItem>
                    <MenuItem value={14}>14</MenuItem>
                    <MenuItem value={16}>16</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="camp-row-enfermedad">
                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  value={row.enfermedad}
                  type="text"
                  label="¿Enfermedad?"
                  onChange={(event) => {
                    handleChange(
                      row.id,
                      "enfermedad",
                      event.target.value.toUpperCase()
                    );
                  }}
                />
              </div>
              <div className="camp-row-alergia">
                <TextField
                  fullWidth
                  size="small"
                  autoComplete="off"
                  value={row.alergia}
                  type="text"
                  label="¿Alergias?"
                  onChange={(event) => {
                    handleChange(
                      row.id,
                      "alergia",
                      event.target.value.toUpperCase()
                    );
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="camp-footer">
        <div></div>
        <div>
          <MiBoton texto="Inscribir!" evtClick={handleGrabar} />
        </div>
        <div></div>
      </div>
    </div>
  );
}
