import React from "react";
import "./../css/EstadoDetallado.css";
import RowTabla from './RowTabla';
import Wait from './Wait';
import MiSelect from './MiSelect';
import MiBoton from './MiBoton';

Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~ ~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};
class EstadoDetallado extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            datos: [],
            ahorro: 0,
            desde: this.inicio(),
            hasta: this.hoy(),
            buscandoDatos: false
        }
        this.actDatos = this.actDatos.bind(this);
        this.props.titulo("Estado de Cuenta Detallado");
        this.evtChange = this.evtChange.bind(this);
        this.evtClickBuscar = this.evtClickBuscar.bind(this);
        this.actDatos();
    }

    async actDatos(){
        if (this.state.ahorro==0){return false;}
        this.setState({
            buscandoDatos: true
        })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        var miData = new URLSearchParams();
        miData.append("cliente", localStorage.getItem("cliente"));
        miData.append("ahorro", this.state.ahorro);
        miData.append("desde", this.state.desde);
        miData.append("hasta", this.state.hasta);
        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
          try {
            const response = await fetch("https://api.cooeprouasd.net/api/Estados/detalle", requestOptions);
            if (response.ok){
                const data = await response.json();
                var a_datos = [];
                var balance = 0.00;
                if (data[0].respuesta=="S"){
                    var a;
                    for(a=0;a<data.length;a++){
                        let item = {};
                        item.id = a;
                        balance += parseFloat(data[a].monto);
                        item.columnas = [data[a].fecha, parseFloat(data[a].monto).format(2,3,',','.'), balance.format(2,3,',','.'), data[a].concepto]
                        
                        a_datos.push(item);
                    }

                    this.setState({
                        datos: a_datos,
                        buscandoDatos: false
                    });
                } else {
                    //alert("mensaje: " + data.mensaje)
                    
                }
            } else {
                //alert("Error de conexión...");
            }
          } catch(error){
            alert("Error: " + error);
          }
    }

    hoy(){
        let cual = new Date();
        var dd = cual.getDate();
        var mm = cual.getMonth() + 1;
    
        var yyyy = cual.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        var today = yyyy + '-' + mm + '-' + dd;
        return today;
    }

    inicio(){
        let cual = new Date();
        var dd = cual.getDate();
        var mm = cual.getMonth() + 1;
    
        var yyyy = cual.getFullYear();

        var today = yyyy + '-01-01';
        return today;
    }
    formatoFecha(fecha) {
        let cual = new Date(fecha);
        var dd = cual.getDate();
        var mm = cual.getMonth() + 1;
    
        var yyyy = cual.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        var today = dd + '/' + mm + '/' + yyyy;
        return today;
    }

    evtChange(event){
        const target = event.target;
        const nombre = target.name;
        this.setState({
            [nombre]: target.value
        })
    }

    evtClickBuscar(event){
        this.actDatos();
    }



    render(){
        
        const obj_wait = (this.state.buscandoDatos?<Wait />: null);
        var datos = {
            titulo: '',
            titulos: [
                {texto: 'Fecha', ancho: '1fr'},
                {texto: 'Monto', ancho: '1fr'},
                {texto: 'Balance', ancho: '1fr'},
                {texto: 'Observación', ancho: '1fr'}
                ],
            justifica:  ['L', 'D', 'D', 'L'],
            datos: this.state.datos
        }; 
        const datos_ah = [{codigo:1, nombre: "Ahorro Normal", padre:0},
                            {codigo:2, nombre: "Ahorro Especial", padre:0},
                            {codigo:3, nombre: "Ahorro Navideño", padre:0}  ,  
                            {codigo:4, nombre: "Club de Viajes", padre:0},
                            {codigo:5, nombre: "Ahorro Escolar", padre:0},
                            {codigo:6, nombre: "Depósito extraordinario", padre:0},
                            {codigo:7, nombre: "Ahorro Infantil", padre:0}
                        ]
        
        return(
            <div className="Div-Estado">
                {obj_wait}
                <div className="RowFiltro2">
                    <div>
                        <div className="RowFiltro">
                            <div><label className=' EtiqOrd'>Tipo de Casa Comercial:</label></div>
                            <MiSelect filas={datos_ah} padre={0} miNombre="ahorro" evtCambios={this.evtChange} valor={this.state.ahorro} />
                        </div>
                        <div className="RowFiltro">
                            <div><label className=' EtiqOrd'>Desde:</label></div>
                            <div><input className="form-control" type="date" name="desde" onChange={this.evtChange} value={this.state.desde} /></div>
                        </div>
                        <div className="RowFiltro">
                            <div><label className=' EtiqOrd'>Hasta:</label></div>
                            <div><input className="form-control" type="date" name="hasta" onChange={this.evtChange} value={this.state.hasta} /></div>
                        </div>
                    </div>
                    <div>
                        <MiBoton texto="Buscar" miNombre="btBuscar" evtClick={this.evtClickBuscar} />
                    </div>
                </div>
                <div><RowTabla  data = {datos}/></div>
            </div>
        )
    }

}

export default EstadoDetallado;