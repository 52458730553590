import React from 'react';
import './../css/Confirmacion.css';
import MiBoton from './MiBoton';

class Confirmacion extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            visible: "fade-in-conf"
        }
        this.cerrarDialogo = this.cerrarDialogo.bind(this);
        this.procesosCierre = this.procesosCierre.bind(this);
        this.evtClick = this.evtClick.bind(this);
    }

    cerrarDialogo(event){
        this.setState({
            visible: "fade-out-conf",
            visible_contenido: 0
        })
        setTimeout(()=>this.procesosCierre(), 100);
    }

    procesosCierre(){
        this.props.evtCerrar();
        this.setState({
            visible: "fade-in-conf",

        })
    }


    evtClick(event){
        this.props.evtClick(event);
    }

    render(){

        return(
            <div className="Fondo-Confirmacion">
                <div className={"Div-Confirmacion " + this.state.visible}  >
                    <div className="Div-Confirmacion-Titulo">Confirmación</div>
                    <div className="Div-Confirmacion-Texto">{this.props.texto}</div>
                    <div className="Div-Confirmacion-Boton">
                        <div></div>
                        <MiBoton enEspera={this.props.enEspera}  texto="Ok" miNombre="btConfirmarOk" evtClick={this.evtClick} />
                        <div></div>
                        <MiBoton texto="Cancel" evtClick={this.cerrarDialogo} />
                        <div></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Confirmacion;