import React from "react";
import CambioClave from "./CambioClave";
import Mensaje from "./Mensaje";

class RecuperacionClave extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            procesando: true,
            cliente:0,
            mensaje: "",
            mensaje_clase: "Error"
        }
        this.verifica = this.verifica.bind(this);
        this.evtCerrarMensaje=this.evtCerrarMensaje.bind(this)
        this.verifica();
    }

    async verifica(){
        this.setState(
            {
                procesando: true
            });
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var miData = new URLSearchParams();
        miData.append("socio", this.props.sesion);

        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/login/recuperacion";
        try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();
                if (data.respuesta=="S"){
                    this.setState({
                        cliente: data.cliente
                    })
                } else {
                    if (data.respuesta=="*"){
                        this.props.cierre();
                    } else {
                        this.setState({
                            mensaje: data.mensaje
                        })
                    }
                    
                }
            } else {
                this.setState({
                    mensaje: "Ha ocurrido un error de conexión, por favor intente más tarde"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Ha ocurrido un error de conexión, por favor intente más tarde"
            })
          }
          this.setState({
              procesando: false
          });
    }

    evtCerrarMensaje(){
        this.setState({
            mensaje: ""
        })
    }

    render() {
        const obj_mensaje = (this.state.mensaje.length>0?<Mensaje texto={this.state.mensaje} clase={this.state.mensaje_clase} evtCerrar={this.evtCerrarMensaje}/>:null);
        const obj_cambio = (this.state.cliente>0?<CambioClave cierre={this.props.cierre} mensaje="Por favor, introduzca su nueva contraseña" cliente={this.state.cliente} />:null);
        return (
            <div>
                {obj_mensaje}
                {obj_cambio}
            </div>
        )
    }

}

export default RecuperacionClave;