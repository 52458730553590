import React from "react";
import './../css/PreguntasLlenar.css';
import Wait from "./Wait";
import RowTabla from "./RowTabla";
import MiBoton from "./MiBoton";
import Mensaje from "./Mensaje";

class PreguntasLlenar extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            procesando: true,
            datos: [],
            respuestas: [],
            mensaje: "",
            clase_mensaje: "Error",
            mensaje_proceso: null
        }
        this.traePreguntas = this.traePreguntas.bind(this);
        this.evtClick = this.evtClick.bind(this);
        this.evtChangeCampos = this.evtChangeCampos.bind(this);
        this.puedeRegistrar = this.puedeRegistrar.bind(this);
        this.evtCerrarMensaje = this.evtCerrarMensaje.bind(this);
        this.evtCierre = this.evtCierre.bind(this);

        this.traePreguntas();
    }

    evtCierre(){
        this.props.cierre();
    }

    async traePreguntas(){
        this.setState(
            {
                procesando: true
            });
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var miData = new URLSearchParams();
        miData.append("socio", this.props.sesion);

        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/login/segpreguntas";
        try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();
                if (data.respuesta=="S"){
                    const a_preguntas = JSON.parse(data.preguntas);
                    var a;
                    var datos = [];
                    let respuestas = [];
                    for(a=0;a<a_preguntas.length;a++){
                        let item = {};
                        item.id = a_preguntas[a].id;
                        item.columnas = [a_preguntas[a].texto, '[campo]:resp'];
                        let nuevo = {id: item.id, valor: ""}
                        respuestas.push(nuevo);
                        datos.push(item);
                    }
                    this.setState({
                        datos: datos,
                        respuestas: respuestas
                    })
                } else {
                    this.setState({
                        mensaje: data.mensaje,
                        mensaje_proceso: this.evtCierre
                    })
                }
            } else {
                this.setState({
                    mensaje: "Ha ocurrido un error de conexión, por favor intente más tarde"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Ha ocurrido un error de conexión, por favor intente más tarde"
            })
          }
          this.setState({
              procesando: false
          });
    }

    puedeRegistrar(){
        let puede = true;
        let respuestas = this.state.respuestas;
        let a;
        let contador = 0;
        for(a=0;a<respuestas.length;a++){
            if (respuestas[a].valor.length>0){
                contador++;
            }
        }
        if (contador<3){
            puede = false;
            this.setState({
                mensaje: "Debe contestar al menos 3 preguntas"
            })
        }
        return puede;
    }

    async grabaRespuestas(){
        let respuestas = this.state.respuestas;
        let a;
        let contador = 0;
        let a_resp = [];
        for(a=0;a<respuestas.length;a++){
            if (respuestas[a].valor.length>0){
                let item = {pregunta: respuestas[a].id, respuesta: respuestas[a].valor};
                a_resp.push(item);
            }
        }
        const json = JSON.stringify(a_resp);
        this.setState(
            {
                procesando: true
            });
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var miData = new URLSearchParams();
        miData.append("sesion", this.props.sesion);
        miData.append("respuestas", json);

        var requestOptions = {
            method: 'POST',
            body: miData,
            Headers: myHeaders,
            redirect: 'follow'
        };
        let url = "https://api.cooeprouasd.net/api/login/segrespuestas";
        try {
            const response = await fetch(url, requestOptions);
            if (response.ok){
                const data = await response.json();
                if (data.respuesta=="S"){
                    this.setState({
                        mensaje: "Sus preguntas fueron agregadas con éxito",
                        clase_mensaje: "Info",
                        mensaje_proceso: this.evtCierre
                    })
                } else {
                    this.setState({
                        mensaje: data.mensaje,
                        mensaje_proceso: this.evtCierre
                    })
                }
            } else {
                this.setState({
                    mensaje: "Ha ocurrido un error de conexión, por favor intente más tarde"
                })
            }
          } catch(error){
            this.setState({
                mensaje: "Ha ocurrido un error de conexión, por favor intente más tarde"
            })
          }
          this.setState({
              procesando: false
          });
    }

    evtClick(event){
        if (this.puedeRegistrar()){
            this.grabaRespuestas();
        }
    }

    evtChangeCampos(campo, valor){
        var a;
        let respuestas = this.state.respuestas;
        let id = campo.substr(5, campo.length-4)
        for(a=0;a<respuestas.length;a++){
            if (respuestas[a].id==id){
                respuestas[a].valor = valor;
                break;
            }
        }
        this.setState({
            respuestas: respuestas
        })
    }

    evtCerrarMensaje(){
        this.setState({
            mensaje: ""
        })
        if (this.state.mensaje_proceso!=null){
            this.state.mensaje_proceso();
        }
    }

    render(){
        const obj_wait = (this.state.procesando? <Wait />: null);
        const obj_mensaje = (this.state.mensaje.length>0?<Mensaje texto={this.state.mensaje} clase={this.state.clase_mensaje} evtCerrar={this.evtCerrarMensaje} />: null);
        var a_titulos = [
            {texto: 'Pregunta', ancho: '1fr'},
            {texto: 'Respuesta', ancho: '1fr'}
            ];
        var a_justifica = ['L', 'L'];
        var datos = {
            titulo: ("Preguntas de Seguridad"),
            titulos: a_titulos,
            justifica: a_justifica,
            datos: this.state.datos
        }; 
        return(
            <div className="Fondo-Preguntas">
                {obj_wait}
                {obj_mensaje}
                <div className="Div-Preguntas">
                    <div className="Div-Tabla-Preguntas">
                        <RowTabla data={datos} evtChangeCampos={this.evtChangeCampos} />
                    </div>
                    <div className="Div-Boton">
                        <div></div>
                        <MiBoton texto="Registrar" evtClick={this.evtClick} />
                        <div></div>
                    </div>
                    
                </div>
            </div>
        )
    }
}

export default PreguntasLlenar;